<template>
  <span>
    <v-row dense>
      <v-col cols="12">
        <v-card class="my-0">
          <v-card-title>
            <h5>Serviços Pendentes</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartPendentesOptions 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-card class="my-0">
          <v-card-title>
            <h5>PSA - Percentual de Serviços em Atraso</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartPsaOptions 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-card class="my-0">
          <v-card-title>
            <h5>PAT - Prazo Médio de Atraso</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartPatOptions 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    
    <v-row dense>
      <v-col cols="12">
        <v-card class="my-0">
          <v-card-title>
            <h5>Compensações</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartCompensacoesOptions 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </span>
</template>

<script>

export default {
  name: "DashboardServicosVisaoMunicipiosCardsKpisPorMunicipio",
  props: {
    dados: {
      type: Array,
      required: true,
    },
    municipios: {
      type: Array,
      required: true,
    },
    metasKpis: {
      type: Object,
      required: true,
    },
    tipoOrdenacao: {
      type: String,
      required: true,
    },
    kpiOrdenacao: {
      type: String,
      required: true,
    },
    exportingOptions : {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    chartPendentesOptions: {},
    chartPsaOptions: {},
    chartPatOptions: {},
    chartCompensacoesOptions: {},
  }),
  computed: {
    categories() {
      const sortedValues = this.tipoOrdenacao === 'asc' 
        ? this.dados.sort((a, b) => Number(a[this.kpiOrdenacao] ?? 0) - Number(b[this.kpiOrdenacao] ?? 0))
        : this.dados.sort((a, b) => Number(b[this.kpiOrdenacao] ?? 0) - Number(a[this.kpiOrdenacao] ?? 0));

      return sortedValues.map(el => el.nome_municipio);
    },
  },
  mounted() {
    this.setChartsOptions();
  },
  methods: {
    setChartsOptions() {
      this.setChartPendentesOptions();
      this.setChartPsaOptions();
      this.setChartPatOptions();
      this.setChartCompensacoesOptions();
    },
    getColumnChartDefaultOptions(categories = this.categories) {
      return {
        chart: {
          type: 'column',
          height: 400,
          zoomType: 'x',
        },
        credits: { enabled: false },
        title: { text: null },
        subtitle: { text: null },
        legend: { enabled: false },
        xAxis: {
          categories: categories,
          crosshair: true,
          title: { 
            text: 'Município'
          }
        },
        yAxis: {
          title: { text: null }
        },
        lang: {
          noData: "Sem dados para exibição"
        },
        series: [],
        exporting: this.exportingOptions,
      }; 
    },
    setChartPendentesOptions() {
      let options = this.getColumnChartDefaultOptions();

      options.series = {
        name: "Pendentes",
        animation: false,
        data: this.dados.map(dados => {
          return { 
            x: this.municipios.indexOf(dados.nome_municipio), 
            y: Number(dados.pendentes), 
            color: Number(dados.pendentes) > Number(this.metasKpis?.[dados.codigo_ibge]?.pendentes) ? "#FF5252" : "#2196F3"
          };
        })
      };

      this.chartPendentesOptions = options;
    },
    setChartPsaOptions() {
      let options = this.getColumnChartDefaultOptions();

      options.yAxis.max = 100;

      options.series = {
        name: "PSA",
        animation: false,
        data: this.dados.map(dados => {
          return { 
            x: this.municipios.indexOf(dados.nome_municipio), 
            y: Number(dados.psa), 
            color: Number(dados.psa) > Number(this.metasKpis?.[dados.codigo_ibge]?.psa) ? "#FF5252" : "#2196F3"
          };
        })
      };

      this.chartPsaOptions = options;
    },
    setChartPatOptions() {
      let options = this.getColumnChartDefaultOptions();

      options.series = {
        name: "PAT",
        animation: false,
        data: this.dados.map(dados => {
          return { 
            x: this.municipios.indexOf(dados.nome_municipio), 
            y: Number(dados.pat), 
            color: Number(dados.pat) > Number(this.metasKpis?.[dados.codigo_ibge]?.pat) ? "#FF5252" : "#2196F3"
          };
        })
      };

      this.chartPatOptions = options;
    },
    setChartCompensacoesOptions() {
      let options = this.getColumnChartDefaultOptions();

      options.series = {
        name: "Compensações",
        animation: false,
        data: this.dados.map(dados => {
          return { 
            x: this.municipios.indexOf(dados.nome_municipio), 
            y: Number(dados.compensacoes), 
            color: Number(dados.compensacoes) > Number(this.metasKpis?.[dados.codigo_ibge]?.compensacoes) ? "#FF5252" : "#2196F3"
          };
        })
      };

      this.chartCompensacoesOptions = options;
    },
  },
  watch: {
    dados() {
      this.setChartsOptions();
    },
    categories() {
      this.setChartsOptions();
    },
  }
}

</script>

<style>

</style>